// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { RoughEase } from "gsap/EasePack"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import farmbotImg from "./assets/farmbot.png"
import rabbitImg from "./assets/rabbit.png"

// Sprites
import thistleImg from "./assets/sprites/thistle.png"
import vineImg from "./assets/sprites/vine.png"
import batImg from "./assets/sprites/bat.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 3,
  preHeading: "Dear Green Places",
  heading: "This must be the place where growth comes in green.",
  content:
    "Where the lungs of the city are in rude health. Where we amble down avenues and pedal down pathways to the backbeat of birdsong. This is where we redefine urbanism and reclaim our streets from the creep of concrete and the tyranny of traffic. We plant pocket parks in every patch and tend to gardens in every green gap. Here, we breathe deep the clean air that's carried on the wind.",
  slug: "dear-green-places",
  nextSlug: "technology-and-nature",
}

gsap.registerPlugin(RoughEase)

const DearGreenPlaces = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -50, y: 713 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 950 },
    }),
    farmbot = useRef({
      parallax: 0.5,
      src: farmbotImg,
      el: null,
      offset: { x: 1276, y: 1532 },
    }),
    rabbit = useRef({
      parallax: 0.5,
      src: rabbitImg,
      el: null,
      offset: { x: 1897, y: 1700 },
    }),
    thistle = useRef({
      parallax: 0.2,
      src: thistleImg,
      el: null,
      offset: { x: 1605, y: 429 },
      size: { w: 140, h: 250 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    }),
    vine = useRef({
      parallax: 1,
      src: vineImg,
      el: null,
      offset: { x: 2110, y: 1270 },
      size: { w: 270, h: 580 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    }),
    bat = useRef({
      parallax: 6,
      src: batImg,
      el: null,
      offset: { x: 2190, y: 1290, r: 20 },
      size: { w: 150, h: 60 },
      rotationPoint: { x: 75, y: 15 },
      currentSprite: 1,
      spriteFrames: 6,
      slowFactor: 2,
      loop: true,
      hidden: true,
    }),
    bat2 = useRef({
      parallax: 10,
      src: batImg,
      el: null,
      offset: { x: 2150, y: 1290, r: -20 },
      size: { w: 150, h: 60 },
      rotationPoint: { x: 75, y: 15 },
      currentSprite: 4,
      spriteFrames: 6,
      slowFactor: 2,
      loop: true,
      hidden: true,
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    mg.current,
    farmbot.current,
    rabbit.current,
    thistle.current,
    fg.current,
    vine.current,
    bat.current,
    bat2.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    thistle.current,
    vine.current,
    bat.current,
    bat2.current,
  ])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 1755,
    y: 1467,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.fromTo(
      farmbot.current.offset,
      { x: 1276 },
      { x: 1452, duration: 6, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
  }

  // Timed animations
  const showVine = () => {
    let batEase =
      "rough({ template: none.out, strength: 0.2, points: 40, taper: 'none', randomize: true, clamp: false})"
    vine.current.paused = false
    bat.current.hidden = false
    bat2.current.hidden = false
    gsap.to(bat.current.offset, { x: 1800, duration: 4, ease: "power1.inOut" })
    gsap.to(bat.current.offset, { y: 1050, duration: 4, ease: batEase })
    gsap.to(bat.current.offset, {
      y: 1150,
      duration: 4,
      ease: batEase,
      repeat: -1,
      delay: 4,
      repeatDelay: 0,
      yoyo: true,
    })
    gsap.fromTo(
      bat.current.offset,
      { r: 20 },
      { r: -20, duration: 2, ease: batEase, repeat: -1, yoyo: true }
    )
    gsap.to(bat2.current.offset, { x: 1650, duration: 5, ease: "power1.inOut" })
    gsap.to(bat2.current.offset, { y: 1150, duration: 5, ease: batEase })
    gsap.to(bat2.current.offset, {
      y: 1150,
      duration: 7,
      ease: batEase,
      repeat: -1,
      delay: 5,
      repeatDelay: 0,
      yoyo: true,
    })
    gsap.fromTo(
      bat2.current.offset,
      { r: -30 },
      { r: 10, duration: 1.6, ease: batEase, repeat: -1, yoyo: true }
    )
  }

  // Triggered animations
  const showRabbit = direction => {
    if (direction === "in") {
      gsap.to(rabbit.current.offset, {
        x: 1847,
        duration: 0.4,
        ease: "power4.out",
      })
    } else {
      gsap.to(rabbit.current.offset, {
        x: 1897,
        duration: 0.65,
        ease: "power1.out",
      })
    }
  }

  const showThistle = () => {
    thistle.current.paused = false
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
    }, 1000)
    let showVineTimeout = setTimeout(showVine, 6000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
      if (showVineTimeout) clearTimeout(showVineTimeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showRabbit,
              x: 1710,
              y: 1580,
              w: 518,
              h: 389,
            },
            {
              event: showThistle,
              x: 1480,
              y: 240,
              w: 400,
              h: 530,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default DearGreenPlaces
